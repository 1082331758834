<template>
  <div class="introduction">
    <mds-layout-grid>
      <mds-row>
        <mds-col class="introduction-section">
          <div class="product-introduction-title">The Problem</div>
          <div class="center-align">
            <span class="normal-text"> CMBS data is </span>
            <span class="bold-text">imperfect, slow to access,</span>
            <span class="normal-text"> and </span>
            <span class="bold-text"> hard to analyze.</span>
          </div>
          <div class="product-introduction-text-align">
            <span class="normal-text"> This means too much time is spent on</span>
            <span class="bold-text"> data collection and analysis</span>
            <span class="normal-text"> to make sense of it all.</span>
          </div>
        </mds-col>
      </mds-row>
    </mds-layout-grid>
  </div>
</template>

<script>
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';

export default {
  name: "Introduction",
  components : {
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '@mds/constants';
  @import '@mds/fonts';
  @import '@mds/typography';

.introduction {
  top: 442px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-top: calc(#{$mds-space-8-x} + #{$mds-space-three-quarter-x});
  margin-bottom: calc(#{$mds-space-8-x} + #{$mds-space-three-quarter-x});
  @include mds-level-3-heading()
}
.product-introduction-title {
  font-weight: 700;
  line-height: 34px;
  text-align: center;
  font-size: 32px;
  letter-spacing: 0.01em;
  margin-bottom: 20px;
}
.center-align {
  text-align: center;
}
.bold-text {
  font-weight: 700;
  font-size: 28px;
  line-height: 42px;
}
.normal-text {
  font-weight: 300;
  font-size: 28px;
  line-height: 42px;
}
.product-introduction-text-align {
  @media (max-width: $mds-breakpoint-m) {
    text-align: center;
  }
}
</style>