<template>
  <div class="mcia-outer-conatiner">
    <div class="mcia-inner-container">
      <mds-layout-grid class="product-details-section">
        <mds-row v-if="flipContent">
          <mds-col class="product-details-section-row-flippedimg">
            <img :src="imagePath" />
          </mds-col>
          <mds-col class="product-details-section-row-description">
            <div class="item-intro" v-html="introduction"></div>
            <br />
            <div
              v-for="(item, index) in keyPoints"
              class="display-content"
              :key="`key-points-${index}`"
            >
              <ul>
                <li>{{ item }}</li>
              </ul>
            </div>
          </mds-col>
        </mds-row>
        <mds-row v-else>
          <mds-col class="product-details-section-row-description">
            <div class="item-intro" v-html="introduction"></div>
            <br />
            <div
              v-for="(item, index) in keyPoints"
              class="display-content"
              :key="`key-points-${index}`"
            >
              <ul>
                <li>{{ item }}</li>
              </ul>
            </div>
          </mds-col>
          <mds-col class="product-details-section-row-img">
            <img :src="imagePath" />
          </mds-col>
        </mds-row>
      </mds-layout-grid>
    </div>
  </div>
</template>

<script>
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';

export default {
  name: 'PlatformItem',
  components: {
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
  },
  data() {
    return {
      toggle: false,
      showNotification: false,
      flipContent: false,
    };
  },
  computed: {
    imagePath() {
      return require('../../../assets/' + this.imageFileName);
    }
  },
  props: {
    introduction: {
      type: String,
      default: '',
      required: true,
    },
    keyPoints: {
      type: Array,
      required: true,
    },
    imageFileName: {
      type: String,
      default: '',
      required: true,
    },
    flipped: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleWindowResize);
    this.handleWindowResize();
  },
  methods: {
    handleWindowResize() {
      if (window.outerWidth <= 768) {
        this.flipContent = false;
      } else {
        this.flipContent = this.flipped;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.product-details-section {
  height: auto;
  width: 100%;
  &-row {
    &-img {
      display: flex;
      justify-content: right;
      align-items: center;
      margin-right: calc(#{$mds-space-3-and-a-half-x} * 10);
    }
    &-flippedimg {
      display: flex;
      justify-content: left;
      margin-left: calc((#{$mds-space-8-x} * 4) - #{$mds-space-1-and-a-half-x});
      align-items: center;
    }
  }
}
.mcia-outer-conatiner {
  margin-top: calc(#{$mds-space-8-x} + #{$mds-space-three-quarter-x});
  .mcia-inner-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.item-intro{
  @include mds-level-2-heading();
  font-weight: 700;
  font-size: 28px;
  // width: 541px;
}
ul {
  width: 590px;
  margin-right: calc((#{$mds-space-7-x} * 5) + #{$mds-space-three-quarter-x});
  @include mds-unordered-list(large);
}
ul li {
  &::before{
    color: $mds-text-color-primary;
  }
  line-height: 29px;
}
.product-details-section-row-img {
  @media (max-width: $mds-breakpoint-m) {
    margin-right: calc(#{$mds-space-3-and-a-half-x} * 3);
  }
}
.display-content {
  display: flex;
}
img {  
  @media (max-width: $mds-breakpoint-m) {
    margin-top: $mds-space-2-x;
  }
}
</style>
